import type { RpcMethodParameters } from '@scayle/storefront-nuxt'

type Options = Partial<{
  params: MaybeRefOrGetter<RpcMethodParameters<'getNewsletterSubscription'>>
  options?: Parameters<typeof useRpc<'getNewsletterSubscription'>>[3]
  key: string
}>

export async function useNewsletterSubscription({
  key = 'useNewsletterSubscription',
  params,
  options,
}: Options = {}) {
  /**
   * Method for submitting the newsletter form
   */
  const {
    data: submittedData,
    fetching: submitting,
    fetch: submit,
    error,
    status,
  } = await useRpc('getNewsletterSubscription', key, params, options)

  return {
    submittedData,
    submitting,
    submit,
    error,
    status,
  }
}
